/* profile_popup.css */

.profile-main {
    position: fixed;
    top: 0%;
    right: -100%;
    width: 500px;
    height: 100%;
    background-color: #fff;
    transition: right 0.7s ease-out;
    height: 100vh;
    z-index: 999;
    /* overflow-y: auto;
    overflow-x: hidden; */
}

.profile-main::-webkit-scrollbar {
    display: none;
}

.profile-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.profile-main.show-profile {
    position: fixed;
    top: 0%;
    width: 500px;
    height: 100%;
    background-color: #fff;
    transition: right 0.7s ease-out;
    height: 100vh;
    right: 0;
    
    /* left: initial; */
    transition: right 0.3s ease-out;
}


.profile-main.profile-active {
    opacity: 1;
    visibility: visible;
}

.profile-main .head {
    width: 100%;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 7%);
    padding: 25px 24px 18px 30px;
    display: flex;
    justify-content: space-between;
}

.profile-main .head h2 {
    font-weight: 600;
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
}

.profile-main .head button,
.filter-main .head button {
    background: none;
    border: none;
}

.profile-main .details {
    width: 100%;
    padding: 15px 28px;
}

.profile-main .details .profile-upload {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 18px;
    margin: 0 0 25px;
}

.profile-main .details .profile-upload .profile-img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #F2F2F2;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

}

.profile-main .details .profile-upload .profile-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.profile-main .details .profile-upload span {
    width: calc(100% - 120px);
    position: relative;
    font-weight: 600;
    font-size: 14px;
    color: #1980DA;
    cursor: pointer;
}

.profile-main .details .profile-upload span input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
}

.profile-main .details .input-group {
    width: 100%;
}

.profile-main .details .input-group .input-field {
    width: 100%;
    margin: 0 0 25px;
}

.profile-main .details .input-group .input-field .dial-code {
    width: 100%;
    position: relative;
}

.profile-main .details .input-group .input-field .dial-code select {
    position: absolute;
    border: none;
    background: none;
    width: 70px;
    outline: none;
    left: 5px;
    top: 5px;
}
/* .profile-main .details .input-group .input-field .dial-code input {
    padding: 0 0 0 80px;
} */

.profile-main .details .input-group .input-field label {
    color: #4f4f4f;
    font-size: 16px;
    font-weight: normal;
    font-family: 'Open Sans', sans-serif;
    display: block;
    margin: 0 0 6px;
}

.profile-main .details .input-group .input-field input {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #E0E0E0;
    outline: none;
    padding: 0 22px;
}

.profile-main .details .input-group .input-field input::placeholder {
    color: #BDBDBD;
    font-weight: normal;
    font-family: 'Open Sans', sans-serif;
}

.profile-main .details .input-group .input-field .change-password {
    width: 100%;
    display: flex;
    position: relative;
}

.profile-main .details .input-group .input-field .change-password button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 21px;
    background: none;
    border: none;
    color: #1980DA;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
}

.profile-main .profile-btn {
    width: 100%;
    border-top: 1px solid #F2F2F2;
    padding: 19px 29px;
    display: flex;
    justify-content: space-between;
}
.save-btn{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:10px
}

.profile-main .profile-btn button {
    width: 100px;
    height: 40px;
    border-radius: 10px;
    background-color: #1980DA;
    border: none;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
}

.profile-main .profile-btn button.logout-btn {
    background-color: #FFE6E6;
    color: #DC0000;
}

@media screen and (max-width:575px) {
    .profile-main .details {
        padding: 15px;
    }
    .profile-main .details .profile-upload span {
        margin: 0 0 0 10px;
    }
    .profile-main .details .profile-upload .profile-img {
        width: 60px;
        height: 60px;
    }
    .profile-main .details .input-group .input-field .dial-code select {
        font-size: 15px;
        width: 50px;
    }
    /* .profile-main .details .input-group .input-field .dial-code input {
        padding: 0 0 0 60px;
    } */

    .profile-main .details .input-group .input-field input {
        height: 45px;
    }
    .profile-main .details .input-group .input-field .change-password button {
        font-size: 13px;
    }
}

