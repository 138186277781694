.detailSlider .slick-dots  {
    position: absolute;
    bottom: 5px;
    right: -325px;
    }
    
.detailSlider .slick-dots .slick-active button:before {
    background-color: #1980DA !important;
    height: 10px;
    width: 20px;
    text-indent: -9999px;
    overflow:hidden; 
    border-radius: 20%;
    }
.detailSlider .slick-dots li button {
    background-color: white;
    height: 10px;
    width: 20px;
    text-indent: -9999px;
    overflow:hidden; 
    border-radius: 20%;
    }
.detailSlider .slick-dots li button:before {
    background-color: white;
    height: 10px;
    width: 20px;
    text-indent: -9999px;
    overflow:hidden; 
    border-radius: 20%;
    }

.slick-dots li button:before {
    background-color: #1980DA ;
    height: 10px;
    width: 10px;
    text-indent: -9999px;
    overflow:hidden; 
    border-radius: 100%;
    }