#header-main {
    width: 100%;
    display: inline-block;
    background-color: #fff;
    box-shadow: 0 4px 50px rgba(25, 128,218,25%);
    position: sticky;
    top: 0;
    left: 0;
    z-index: 12;
}


.profileImg {
  cursor: pointer;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.profile_name {
    background-color: #bebebe61;
    text-transform: capitalize;
    border-radius: 50px;
    padding: 0;
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 12px 37px 12px 37px;
}

.header .header-left, .header .header-right {
    width: auto;
    display: flex;
    align-items: center;
    gap: 30px;
}

.header .header-right .header-login-btn {
    display: flex;
    width: auto;
    gap: 30px;
}
.header .header-right .header-login-btn.disable {
    display: none;
}
.header .header-left a ,  .header .header-right button{
    display: inline-block;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: #131313;
}
.header .header-left a img {
    width: 318px;
}

.header .header-left, .header .header-right-profile {
    width: auto;
    display: flex;
    align-items: center;
    gap: 30px;
}

.header .header-right-profile .header-login-btn {
    display: flex;
    width: auto;
    gap: 30px;
}
.header .header-right-profile {
    width: auto !important;
}
.header .header-right button {
    width: 105px;
    height: 45px;
    border-radius: 10px;
    border: 1px solid #99CFFF;
    background: none;
    color: #1980DA;
    font-size: 16px;
}
.header .header-right button.active-btn {
    background-color: #1980DA;
    color: #fff;
}
.header .header-right .profile {
    width: auto;
    /* display: none; */
    cursor: pointer;
}
.header .header-right .profile.active {
    display: unset;
}
.header .header-right .profile a {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #f2f2f2;
}

.active{
    color: #1980DA !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E5F1FC;
    padding: 12px;
    border-radius: 4px;
}
.Iconactive {
    color: #1980DA !important;
    display: flex;
    align-items: center;
    justify-content: center;

}
.header .header-right .profile a img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

@media screen and (max-width:575px) {
    .header .header-left a {
        font-size: 14px;
    }
    .active {
        padding: 0;
        background-color: transparent;
        width: 25px;
    }
    .active svg {
        width: 25px;
    }

    .profile_name {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
    }
}