.filter-bar-col {
    width: 100%;
    /* float: left; */
    padding: 0 15px;
}
/* filter Select Box css  */
.filter_select_Option { 
    -moz-appearance: none; 
    -webkit-appearance: none; 
  } 



  /* for IE10 */ 
  .filter_select_Option::-ms-expand { 
    display: none; 
  } 

  #slectoptions * option {
    background-color: red;
  }

  .filter_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

.filter_select_Option {
    border: none;
    width: 100%;
    color: #1980DA;
    font-weight: 600;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    margin: 7px  0 0;
    background-color: transparent;
    z-index: 1;
}
.filter_select_Option option {
  border: 1px solid ;
}

.filter_select_icon {
  background-color: #eaf4fd;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  justify-content: center;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 5px;
}

.filter_select_Option:focus {
    outline: none;
    border-color: none; 
  }

.filter_select_Option option {
    background-color: #f9f9f9;
    color: #333;
    font-size: 14px;
  }

.filter-bar-main {
    width: 100%;
    margin: 0 0 66px;
    position: relative;
}

.filter-bar-main .filter-bar {
    width: 100%;
    display: inline-block;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 0 4px #1980DA;
    display: flex;
    padding: 29px 36px;
    gap: 40px;
}

.filter-bar-main .filter-bar .filter {
    width: 20%;
    border-right: 1px solid #000;
    padding: 0 20px 0 0;
}

.filter-bar-main .filter-bar .filter button.search-btn {
    width: 100%;
    height: 50px;
    background-color: #1980DA;
    border-radius: 12px;
    color: #fff;
    font-weight: normal;
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
    border: none;
    width: 120px;
}

/* dropdown */
.filter-dropdown-main {
  width: 100%;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  position: absolute;
  top: 30px;
  left: 0;

  z-index: 1;
 
  box-shadow: 0 4px 18px rgba(0,0,0,9%);
}
.filter-dropdown-main ul {
  text-align: left;
}
.filter-dropdown-main ul li {
  font-size: 14px;
  color: #000;
  padding: 10px 0 10px 21px;
  border-bottom: 1px solid #EFEFEF;
}

@media screen and (max-width:575px) {
  .filter_select_Option {
    font-size: 18px;
  }
  .filter-bar-col {
    padding: 0;
  }

}

