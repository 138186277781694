.forgot-btn {
    margin: 38px 0 0;
}

@media screen and (max-width:575px) {
    .forgot-btn {
        margin: 15px 0 0;
    }
    .forgot-password a {
        font-size: 18px;
        line-height: 18px;
    }
    .forgot-password {
        margin: 0 0 20px;
    }
    /* .banner-text .forgot-right .login-main .input-field .input-left input.password-place {
        padding-top: 0 !important;
    } */
    .banner-text .forgot-right .forgot-input-main .input-field .input-left input.password-place {
        padding-top: 0 !important;
    }
    .banner-text .forgot-right .login-main .input-field .input-right svg {
        width: 22px;
    }
}