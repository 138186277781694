body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #EDF1F9;
  color: #000;
}

*{
  padding: 0;
  margin: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

button {
  cursor: pointer;
}