.banner-text .banner-right .login-main,
.banner-text .banner-right .register-main {
    width: 100%;
}

.banner-text .banner-right .register-main {
    display: none;
}

.banner-text .banner-right .login-main h2,
.banner-text .banner-right .register-main h2 {
    font-weight: bold;
    font-size: 60px;
    margin: 0 0 40px;
}

.banner-text .banner-right .login-main .input-field,
.banner-text .banner-right .register-main .input-field {
    width: 576px;
    height: 86px;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
    margin: 0 0 18px;
}

.banner-text .banner-right .login-main .input-field .input-left,
.banner-text .banner-right .register-main .input-field .input-left {
    width: 90%;
}

.forgot-password {
    width: 100%;
    text-align: right;
    margin: 0 0 38px;
} 
.forgot-password a {
    color: #1980DA;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    font-family: 'Open Sans', sans-serif;
    cursor: pointer;
}

.banner-text .banner-right .login-main .input-field .input-left label,
.banner-text .banner-right .register-main .input-field .input-left label {
    width: 100%;
    display: block;
    font-weight: 500;
    font-size: 14px;
    margin: 0 0 6px;
    color: rgba(238, 238, 238, 70%);
}

.banner-text .banner-right .login-main .input-field .input-left input,
.banner-text .banner-right .register-main .input-field .input-left input {
    background: transparent;
    border: none;
    width: 100%;
    height: 35px;
    outline: none;
    color: #fff;
    font-size: 24px;
    /* padding: 10px 0 0; */
}

.banner-text .banner-right .login-main .input-field .input-left input::placeholder,
.banner-text .banner-right .register-main .input-field .input-left input::placeholder {
    color: #EEEEEE;
    font-weight: 500;
    font-size: 24px;
}

.banner-text .banner-right .login-main .input-field .input-right,
.banner-text .banner-right .register-main .input-field .input-right {
    width: 10%;
    text-align: right;
}

.banner-text .banner-right .login-main .new-member,
.banner-text .banner-right .register-main .already-member {
    width: auto;
    margin: 0 0 38px;
}

.banner-text .banner-right .login-main .new-member {
    margin: 38px 0 0 !important;
    display: flex;
    justify-content: center;
}
.banner-text .banner-right .login-main .new-member p {
    gap: 20px !important;
}

.banner-text .banner-right .login-main .new-member p,
.banner-text .banner-right .register-main .already-member p {
    font-weight: 600;
    font-size: 24px;
    display: flex;
    gap: 24px;
    font-family: 'Open Sans', sans-serif;
}

.banner-text .banner-right .login-main .new-member p span,
.banner-text .banner-right .register-main .already-member p span {
    color: #1980DA;
}

.banner-text .banner-right .login-main .login-btn-main,
.banner-text .banner-right .register-main .login-btn-main {
    width: 100%;
}

.forgot-btn {
    margin: 38px 0 0;
}

.banner-text .banner-right .login-main .login-btn-main button.login-btn,
.banner-text .banner-right .register-main .login-btn-main button.login-btn {
    width: 100%;
    height: 68px;
    background-color: #1980DA;
    border-radius: 12px;
    border: none;
    color: #fff;
    font-weight: 500;
    font-size: 24px;
}

.sign_Up_btn {
    cursor: pointer !important;
}


@media screen and (max-width:575px) {
    .banner-text .banner-right .login-main .new-member p {
        gap: 5px !important;
    }
    .banner-text .banner-right .login-main .new-member {
        margin: 15px 0 0 !important;
    }
}