@media screen and (max-width:1279px) {
    .banner-text {
        flex-wrap: wrap;
        gap: 45px;
    }

    .banner-text .banner-left,
    .banner-text .banner-right {
        width: 100%;
    }

    .banner-img img {
        height: 1200px;
    }

    .header {
        padding: 25px 15px;
    }

    .banner-text .banner-right .login-main .login-btn-main button.login-btn,
    .banner-text .banner-right .register-main .login-btn-main button.login-btn {
        width: 350px;
    }

    /* .filter-main {
        position: absolute !important;
        top: 181% !important;
        width: 92% !important;
    } */

    .filter-main .filter-select .filter .input-select .input-field {
        width: 100%;
    }

    .filter-main .filter-select-two {
        flex-wrap: wrap;
        row-gap: 25px;
    }

    .filter-main .filter-select .filter-Ada {
        width: 60%;
    }

    .filter-main .filter-select .filter .input-select .input-field label {
        font-size: 14px;
    }
}

@media screen and (max-width:1099px) {
    .banner-text-main {
        top: 100px;
        transform: none;
    }

    .filter-home-main .home-details .home-price .price span {
        font-size: 26px;
    }

    .filter-home-main .home-details .home-price .price h4 {
        font-size: 20px;
    }

    .filter-bar-main .filter-bar {
        padding: 15px;
    }

    .filter-bar-main .filter-bar .filter.btn {
        padding: 0;
    }

    .filter-bar-main .filter-bar {
        flex-wrap: wrap;
        gap: 20px;
    }

    .filter-bar-main .filter-bar .filter {
        width: 23%;
    }

    .home-left-main .additional-info ul li {
        width: 100%;
    }
}

@media screen and (max-width:991px) {
    .header {
        flex-wrap: wrap;
    }

    .banner-text-main {
        top: 65px;
    }

    .banner-text .banner-left h1 {
        font-size: 45px;
    }

    .banner-text .banner-left span {
        font-size: 24px;
    }

    .banner-text .banner-left a {
        width: 202px;
        height: 50px;
    }

    .banner-text .banner-right .login-main .login-btn-main button.login-btn,
    .banner-text .banner-right .register-main .login-btn-main button.login-btn {
        height: 50px;
        width: 240px;
    }

    .header .header-left,
    .header .header-right {
        gap: 0;
    }

    .banner-text .banner-right .login-main h2,
    .banner-text .banner-right .register-main h2 {
        font-size: 40px;
        margin: 0 0 20px;
    }

    .banner-text .banner-right .login-main .input-field,
    .banner-text .banner-right .register-main .input-field {
        width: 500px;
    }

    .banner-img img {
        height: 1000px;
    }

    .recovery-home-slider-mobile {
        display: inline-block;
    }

    .recovery-slider-desktop {
        display: none;
    }

    .filter-home-col {
        width: 100%;
    }

    .slick-dots li button:before {
        font-size: 60px;
        color: #1980DA;
    }

    .slick-dots li.slick-active button:before {
        color: #1980DA;
    }

    .next-previous-main {
        margin: 30px 0 0;
    }

    .filter-bar-main .filter-bar .filter {
        width: 30%;
    }

    .recoveryhead {
        margin: 0 0 30px;
    }

    /* .recovery-home-slider-mobile .slider-recovery {
        padding: 0;
    } */

    .home-left-col {
        width: 100%;
    }

    .home-right-col {
        width: 65%;
    }

    .home-left-main .additional-info {
        margin: 30px 0 40px;
    }

    .home-right-main .home-right-details {
        margin: 30px 0 0;
    }
    /* .filter-main {
        position: absolute;
        top: 181%;
        width: 92%;
    } */
    .filter-main .filter-select-two .filter-addition {
        width: 50% !important;
    }

    .filter-main .filter-select .filter-Ada {
        width: 100%;
    }

    .filter-main .filter-select .filter {
        width: 50%;
    }
}

@media screen and (max-width:767px) {
    .header {
        row-gap: 20px;
    }

    .header .header-left,
    .header .header-right {
        justify-content: space-between;
        width: 100%;
    }

    .banner-text .banner-left h1 {
        font-size: 40px;
    }

    .banner-text .banner-left p {
        font-size: 20px;
    }

    .banner-text .banner-left a {
        width: 160px;
        font-size: 18px;
    }

    .banner-text .banner-right .login-main .new-member,
    .banner-text .banner-right .register-main .already-member {
        margin: 0 0 25px;
    }

    .home-head .head-details {
        flex-wrap: wrap;
    }
    /* .filter-main {
        position: absolute !important;
        top: 181% !important;
        width: 92% !important;
    } */

    .filter-main .filter-select {
        flex-wrap: wrap;
    }

    .filter-main .filter-select .filter {
        width: 42%;
    }

    .filter-main .filter-select .filter-Ada {
        width: 100%;
    }

    .filter-main .filter-select {
        row-gap: 30px;
    }
}

@media screen and (max-width:639px) {
    .banner-img img {
        height: 880px;
    }

    .footer-top {
        flex-wrap: wrap;
    }

    .footer-left,
    .footer-right {
        width: 100%;
    }

    .footer-right {
        margin: 30px 0 0;
    }

    .footer-right h2 {
        margin: 0 0 5px;
    }

    .footer-top-main {
        padding: 30px 0;
    }

    .filter-bar-main .filter-bar .filter {
        width: 46%;
    }

    .filter-bar-main .filter-bar .filter button.search-btn {
        height: 50px;
    }

    .next-previous-main ul li span {
        width: 30px;
        height: 30px;
        font-size: 15px;
    }

    .next-previous-main ul li a.previous-btn {
        margin: 0 15px 0 0;
    }

    .next-previous-main ul li a.next-btn {
        margin: 0 0 0 15px;
    }

    .footer-left p {
        margin: 10px 0 20px;
    }

    .home-left-main .home-bad-details {
        margin: 20px 0;
    }

    .home-left-main h3 {
        margin: 0 0 20px;
    }

    .home-left-main .home-img-slide .home-img img {
        height: 400px;
    }

    .home-left-main p {
        font-size: 18px;
    }

    .home-head .head-details .head-left {
        width: 100%;
        margin: 30px 0 0;
    }

    .home-head .head-details button {
        margin: 25px 0 0;
    }

    .home-right-col {
        width: 100%;
    }
}

@media screen and (max-width:575px) {
    .banner-text .banner-left h1 {
        font-size: 32px;
        margin: 5px 0 0;
    }

    .banner-text .banner-left span {
        font-size: 18px;
    }

    .header .header-left a img {
        width: 220px;
    }

    .header .header-right .header-login-btn {
        gap: 15px;
    }

    .header .header-right button {
        height: 45px;
    }

    .banner-text .banner-right .login-main .input-field,
    .banner-text .banner-right .register-main .input-field {
        width: 100%;
        padding: 0 10px;
        height: 65px;
    }

    .banner-text .banner-left p {
        font-size: 16px;
        margin: 18px 0;
    }

    .banner-text .banner-left a {
        height: 45px;
    }

    .banner-text .banner-right .login-main h2,
    .banner-text .banner-right .register-main h2 {
        font-size: 25px;
        margin: 0 0 14px;
    }

    .banner-text .banner-right .login-main .input-field .input-left input::placeholder,
    .banner-text .banner-right .register-main .input-field .input-left input::placeholder {
        font-size: 16px;
    }

    .banner-text .banner-right .login-main .input-field .input-left input,
    .banner-text .banner-right .register-main .input-field .input-left input {
        font-size: 16px;
        padding: 0;
    }

    .banner-text .banner-right .login-main .input-field .input-right span img,
    .banner-text .banner-right .register-main .input-field .input-right span img {
        width: 25px;
    }

    .banner-text .banner-right .login-main .new-member,
    .banner-text .banner-right .register-main .already-member {
        margin: 0 0 15px;
    }

    .banner-text .banner-right .login-main .new-member p,
    .banner-text .banner-right .register-main .already-member p {
        font-size: 18px;
    }

    .banner-text-main {
        top: 35px;
    }

    .header {
        padding: 15px;
    }

    .header .header-right {
        padding-top: 15px;
        border-top: 1px solid #dcdbdb;
        justify-content: space-between;
    }

    .header .header-right .header-login-btn {
        width: 100%;
        justify-content: space-between;
    }

    .footer-left a {
        width: 100%;
    }

    .terms-details .back-btn {
        margin: 0 0 25px;
    }

    .terms-details .back-btn a,
    .home-head a {
        font-size: 20px;
    }

    .terms-details .our-term h2 {
        font-size: 24px;
    }

    .terms-details .our-term {
        margin: 0 0 20px;
    }

    .terms-update {
        margin: 0 0 35px;
    }

    .terms-details .instruction {
        margin: 0 0 15px;
    }

    .services-use p.service-first {
        margin: 0 0 20px;
    }

    .services-use ul li p.mb {
        margin: 0 0 10px;
    }

    .services-use {
        margin: 0 0 30px;
    }

    .term-contact {
        margin: 30px 0 0;
    }

    #terms-main {
        margin: 27px 0 40px;
    }

    .footer-right ul li a {
        font-size: 16px;
        line-height: 27px;
    }

    .home-left-main .home-bad-details {
        flex-wrap: wrap;
    }

    .home-head .head-details .head-left h2 {
        font-size: 25px;
        margin: 10px 0;
    }

    .home-head .head-details .head-left p {
        font-size: 18px;
    }

    .home-head .head-details button {
        width: 160px;
        height: 45px;
    }

    .home-head {
        margin: 0 0 35px;
    }

    .home-left-main h3 {
        font-size: 21px;
    }

    .home-left-main .additional-info ul li,
    .home-left-main .additional-info ul li span,
    .home-right-main .home-right-details .contact-info ul li,
    .home-right-main .home-right-details .contact-info ul li a {
        font-size: 18px;
    }

    .home-right-main .home-right-details,
    .home-left-main .home-bad-details {
        padding: 15px;
    }

    .home-left-main .home-bad-details .detail h5 {
        margin: 0 0 15px;
    }

    .home-right-main .home-right-details .price {
        margin: 0 0 20px;
    }

    .home-left-main .home-bad-details .detail {
        width: 100%;
    }

    .footer-left a,
    .filter-bar-main .filter-bar .filter {
        width: 100%;
    }

    .filter-bar-main .filter-bar .filter {
        border-right: none;
    }

    .recoveryhead h2,
    .filter-main .head h2 {
        font-size: 25px;
    }

    .recoveryhead {
        text-align: left;
        margin: 0 0 20px;
    }

    #search-recovery-main {
        margin: 45px 0;
    }

    .filter-main .filter-select .filter {
        width: 100%;
    }

    .filter-main .filter-select .filter-Ada {
        flex-wrap: wrap;
        gap: 20px;
    }

    .filter-main .filter-btn {
        flex-wrap: wrap;
        padding-left: 0;
    }

    .filter-main .filter-btn button.clear-btn {
        margin: 0 0 10px;
    }

    .filter-bar-main .filter-bar .filter span,
    .filter-bar-main .filter-bar .filter button.filter-dropdown {
        font-size: 18px;
    }

    .filter-main .filter-select-two .filter-addition {
        width: 100% !important;
    }

    .filter-main .filter-btn button {
        width: 135px;
        height: 45px;
    }

    .profile-main.profile-active {
        height: 90vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .change-password-main {
        padding: 15px;
        width: 320px;
    }

    .change-password-main .password-btn button {
        height: 40px;
    }

    .profile-main {
        width: 300px !important;
    }
    .profile-main .details .profile-upload {
        flex-wrap: wrap;
    }
    .profile-main .details .profile-upload span {
        width: 100%;
    }
   
    .banner-text .banner-right .login-main .new-member {
        margin: 15px 0 0  !important;
    }

    .filter-bar-main .filter-bar .filter textarea {
        width: 100%;
       
    }
}