#banner-main {
    width: 100%;
    display: inline-block;
    position: relative;
}

.login-container {
    width: 100%;
    max-width: 1230px;
    padding: 0 15px;
    margin: 0 auto;
}

.banner-img {
    width: 100%;
}

.banner-img img {
    width: 100%;
    object-fit: cover;
    height: 750px;
    vertical-align: top;
}

.banner-text-main {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /* transform: translateY(-50%); */
}

.banner-text {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 140px;
}
.banner-text.login-banner {
    margin: 169px 0 0;
}

.banner-text .banner-left,
.banner-text .banner-right {
    width: 50%;
    color: #fff;
}

.banner-text .forgot-right {
    display: flex;
    align-items: center;
}

.register-banner .banner-left {
    margin: 70px 0 0;
}

.login-banner .banner-left {
    margin: 0;
}

.banner-text .banner-left span {
    font-weight: bold;
    font-size: 29px;
}

.banner-text .banner-left h1 {
    font-weight: bold;
    font-size: 40px;
    /* margin: 18px 0 0; */
}
.banner-text.register-banner .banner-left h1 {
    margin: 18px 0 0;
}

.banner-text .banner-left p {
    font-size: 16px;
    font-weight: normal;
    margin: 16px 0 29px;
    /* max-width: 400px; */
}

.register-banner .banner-left p.light-house {
    margin: 9px 0 60px;
}

.login-banner .banner-left p.light-house {
    font-size: 16px;
    line-height: 23px;
    margin: 9px 0 21px;
}

.banner-text .banner-left h4 {
    font-weight: bold;
    font-size: 25px;
    line-height:30px;
}
.banner-text .banner-left a {
    width: 205px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    color: #fff;
    font-size: 22px;
    font-weight: 500;
    border-radius: 12px;
}

/* .banner-text .banner-right {
    width: 50%;
} */

.banner-text .banner-right .login-main,
.banner-text .banner-right .register-main {
    width: 100%;
}

.banner-text .banner-right .login-main {
    margin:17px 0 0;
}

.banner-text .banner-right .register-main {
    display: none;
    margin: 65px 0 0;
}

.banner-text .banner-right .login-main h2,
.banner-text .banner-right .register-main h2 {
    font-weight: bold;
    font-size: 60px;
    margin: 0 0 40px;
}

.banner-text .banner-right .login-main .input-field,
.banner-text .banner-right .register-main .input-field {
    width: 576px;
    height: 60px !important;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
    margin: 0 0 18px;
}

.banner-text .banner-right .login-main .input-field,
.banner-text .banner-right .register-main .input-field {
    width: 576px;
    height: 86px;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
    margin: 0 0 18px;
}

.banner-text .banner-right .login-main .input-field .input-left,
.banner-text .banner-right .register-main .input-field .input-left {
    width: 90%;
}

.banner-text .banner-right .login-main .input-field .input-left label,
.banner-text .banner-right .register-main .input-field .input-left label {
    width: 100%;
    display: block;
    font-weight: 500;
    font-size: 14px;
    margin: 0 0 6px;
    color: rgba(238, 238, 238, 70%);
}

.banner-text .banner-right .login-main .input-field .input-left input,
.banner-text .banner-right .register-main .input-field .input-left input {
    background: transparent;
    border: none;
    width: 100%;
    height: 35px;
    outline: none;
    color: #fff;
    font-size: 18px;
    /* padding: 10px 0 0; */
}

.banner-text .banner-right .login-main .input-field .input-left input::placeholder,
.banner-text .banner-right .register-main .input-field .input-left input::placeholder {
    color: rgba(238,238,238,0.7);
    font-weight: normal;
    font-size: 18px;
}

.banner-text .banner-right .login-main .input-field .input-right,
.banner-text .banner-right .register-main .input-field .input-right {
    width: 10%;
    text-align: right;
}
.banner-text .banner-right .login-main .input-field .input-right span img,
.banner-text .banner-right .register-main .input-field .input-right span img {
    width: 30px;
}
.banner-text .banner-right .login-main .input-field .input-right span svg,
.banner-text .banner-right .register-main .input-field .input-right span svg {
    width: 30px;
}
.banner-text .banner-right .login-main .new-member,
.banner-text .banner-right .register-main .already-member {
    width: auto;
    margin: 0 0 38px;
}

.banner-text .banner-right .login-main .new-member p,
.banner-text .banner-right .register-main .already-member p {
    font-weight: 600;
    font-size: 24px;
    display: flex;
    gap: 24px;
    font-family: 'Open Sans', sans-serif;
}

.banner-text .banner-right .login-main .new-member p a,
.banner-text .banner-right .register-main .already-member p a {
    color: #1980DA;
}

.banner-text .banner-right .login-main .login-btn-main,
.banner-text .banner-right .register-main .login-btn-main {
    width: 100%;
}

.banner-text .banner-right .login-main .login-btn-main button.login-btn,
.banner-text .banner-right .register-main .login-btn-main button.login-btn {
    width: 100%;
    height: 60px;
    background-color: #1980DA;
    border-radius: 12px;
    border: none;
    color: #fff;
    font-weight: 500;
    font-size: 20px;
}

.powered-by {
    width: 100%;
    padding: 30px;
    text-align: center;
}

.powered-by p {
    font-size: 30px;
    color: #1980DA;
}

@media screen and (max-width:1279px) {
    .banner-text {
        flex-wrap: wrap;
        gap: 45px;
    }

    .banner-text .banner-left,
    .banner-text .banner-right {
        width: 100%;
    }

    .banner-img img {
        height: 1100px;
    }

    .banner-text .banner-right .login-main .login-btn-main button.login-btn,
    .banner-text .banner-right .register-main .login-btn-main button.login-btn {
        width: 350px;
    }

    .banner-text .banner-right .login-main {
        margin: 0;
    }

    .banner-text .banner-right .login-main .login-btn-main button.login-btn,
    .banner-text .banner-right .register-main .login-btn-main button.login-btn {
        width: 576px;
    }

    .banner-text .banner-left {
        margin: 85px 0 0;
    }
    .banner-text.login-banner {
        margin:  0 0;
    }
}

@media screen and (max-width: 1099px) {
    .banner-text-main {
        transform: none;
    }
}

@media screen and (max-width: 991px) {
    .powered-by p {
        font-size: 22px;
    }

    .banner-text .banner-left h1 {
        font-size: 45px;
    }

    .banner-text .banner-left span {
        font-size: 24px;
    }

    .banner-text .banner-left a {
        width: 202px;
        height: 50px;
    }

    .banner-text .banner-right .login-main .login-btn-main button.login-btn,
    .banner-text .banner-right .register-main .login-btn-main button.login-btn {
        height: 50px;
        width: 240px;
    }

    .banner-text .banner-right .login-main h2,
    .banner-text .banner-right .register-main h2 {
        font-size: 40px !important;
        margin: 0 0 20px !important;
    }

    .banner-text .banner-right .login-main .input-field,
    .banner-text .banner-right .register-main .input-field {
        width: 100% !important;
    }

    .banner-img img {
        height: 1000px;
    }

}

@media screen and (max-width:767px) {
    .banner-text .banner-left h1 {
        font-size: 40px;
    }

    .banner-text .banner-left p {
        font-size: 20px;
    }

    .banner-text .banner-left a {
        width: 160px;
        font-size: 18px;
    }

    .banner-text .banner-right .login-main .new-member,
    .banner-text .banner-right .register-main .already-member {
        margin: 0 0 25px;
    }

    .banner-text .banner-right .login-main .login-btn-main button.login-btn,
    .banner-text .banner-right .register-main .login-btn-main button.login-btn {
        width: 300px;
    }
    .banner-img img {
        height: 900px;
    }
}

@media screen and (max-width:639px) {
    .banner-img img {
        height: 880px;
    }
}

@media screen and (max-width: 575px) {
    .banner-text .banner-left h1 {
        font-size: 32px;
        margin: 5px 0 0;
    }

    .banner-text .banner-left span {
        font-size: 18px;
    }

    .banner-text .banner-right .login-main .input-field,
    .banner-text .banner-right .register-main .input-field {
        width: 100% !important;
        padding: 0 10px !important;
        height: 65px !important;
    }

    .banner-text .banner-left p {
        font-size: 16px;
        margin: 18px 0;
    }

    .banner-text .banner-left a {
        height: 45px;
    }

    .banner-text .banner-right .login-main h2,
    .banner-text .banner-right .register-main h2 {
        font-size: 25px !important;
        margin: 0 0 14px !important;
    }

    .banner-text .banner-right .login-main .input-field .input-left input::placeholder,
    .banner-text .banner-right .register-main .input-field .input-left input::placeholder {
        font-size: 16px;
    }

    .banner-text .banner-right .login-main .input-field .input-left input,
    .banner-text .banner-right .register-main .input-field .input-left input {
        font-size: 16px;
        padding: 0;
    }

    .banner-text .banner-right .login-main .input-field .input-right span img,
    .banner-text .banner-right .register-main .input-field .input-right span img {
        width: 25px;
    }

    .banner-text .banner-right .login-main .new-member,
    .banner-text .banner-right .register-main .already-member {
        margin: 0 0 15px;
    }

    .banner-text .banner-right .login-main .new-member p,
    .banner-text .banner-right .register-main .already-member p {
        font-size: 18px;
    }

    .banner-text-main {
        top: 35px;
    }
    .banner-text .banner-left {
        margin: 20px 0 0;
    }

    .banner-text .banner-right .register-main {
        margin: 30px 0 0;
    }
    .login-banner .banner-left p.light-house {
        font-size: 14px;
    }
}