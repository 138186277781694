.logout-main {
    width: 340px;
    text-align: center;
    background-color: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    padding: 18px 0 38px;
    transform: translate(-50%, -50%);
    z-index: 1111;
    border-radius: 10px;
}

.logout-main.logout-active {
    opacity: 1;
    visibility: visible;
}


.logout-main h2 {
    font-size: 20px;
    line-height: 18px;
    margin: 20px 0 0;
    font-weight: 600;
}

.logout-main p {
    color: #828282;
    line-height: 20px;
    margin: 15px 0 29px;
    padding: 0 30px;
}
.logout-main .logout-btn {
    width: 100%;
}

.logout-main .logout-btn button {
    width: 94px;
    height: 40px;
    background-color: #fff;
    color: #1980DA;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    border: none;
    margin: 0 10px 0 0;
    border-radius: 10px;
}

.logout-main .logout-btn button.yes-btn {
    background-color: #1980DA;
    color: #fff;
}

@media screen and (max-width:575px) {
    .logout-main {
        width: 300px;
    }
}