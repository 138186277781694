

.overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 50%);
    opacity: 1;
    z-index: 1;

    /* visibility: hidden; */
}

.overlay.change-active {
    opacity: 1;
    visibility: visible;
}

.overlay.profile-active {
    opacity: 1;
    visibility: visible;
}

.overlay.filter-active {
    opacity: 1;
    visibility: visible;
}

.filter-main.filter-active {
    opacity: 1;
    visibility: visible;
}

.filter-main {

    width: 100%;
    max-width: 1344px;
    background-color: #fff;
    position: absolute;
    top: 165px;
    z-index: 11;
    left: 0;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 4px rgba(25, 128, 218, 25%);
    /* top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
}

.filter-main .head {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.filter-main .head h2 {
    font-size: 26px;
    line-height: 44px;
    letter-spacing: 1px;
    color: #181D24;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
}

.filter-main .head button {
    background: none;
    border: none;
}

.filter-main .filter-select {
    width: 100%;
    display: flex;
    gap: 85px;
    margin: 0 0 20px;
}

.filter-main .filter-select-two {
    width: 100%;
}

.filter-main .filter-select-two .filter .input-select {
    display: inline-block !important;
}

.filter-main .filter-select-two .filter-addition {
    width: 30% !important;
}

.filter-main .filter-select-two .filter .input-select .input-field {
    width: 100% !important;
    margin: 0 0 11px !important;
}

.filter-main .filter-select .filter {
    width: 33.33%;
}

.filter-main .filter-select .filter-Ada {
    width: 40%;
    display: flex;
    gap: 85px;
}

.filter-main .filter-select .filter-Ada .filter-first {
    width: auto;
}

.filter-main .filter-select .filter-Ada .filter-two {
    width: auto;
}

.filter-main .filter-select .filter label {
    color: #181D24;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Open Sans', sans-serif;
    display: block;
    margin: 0 0 11px;
}

.filter-main .filter-select .filter .input-select {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    /* row-gap: 17px; */
    column-gap: 10px;
}

.filter-main .filter-select .filter .input-select .input-field {
    width: 48%;
    display: flex;
    gap: 11px;
    align-items: center;
}

.filter-main .filter-select .filter .input-select .input-field input {
    width: 18px;
    height: 18px;
    border: 2px solid #181D24;
}

.filter-main .filter-select .filter .input-select .input-field label {
    color: #181D24;
    font-size: 14px;
    margin: 0;
    line-height: 22px;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
}

.filter-main .filter-btn {
    width: 100%;
    border-top: 1px solid #F5F3F1;
    padding: 20px 0 0 0;
    display: flex;
    justify-content: space-between;
}

.filter-main .filter-btn button {
    width: 105px;
    height: 40px;
    background-color: #1980DA;
    border: none;
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
}

.filter-main .filter-btn button.clear-btn {
    background-color: #E1F0FD;
    color: #1980DA;
}


@media screen and (max-width:450px) {
    .filter-main {
        padding: 10px;
    }

    .filter-bar-main .filter-bar .filter.btn {
        width: 100%;
    }
    .filter-main {
        top: 0;
    }

   
}