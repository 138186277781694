 /* remove number input filed indicators */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.verify-email{
    color: #000;
    font-weight: bold;
}


/* verify otp popup */

.otp-Verification-overlay {
    
    width: 100% !important;
    height: 100% !important;
    background-color: rgba(0, 0, 0, 0.5) !important; /* Adjust the alpha value for transparency */
    z-index: 99999 !important; 
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;

}
.otp-main {
    width: 400px;
    background-color: #fff;
    padding: 24px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 7;
    z-index: 999999;
    border-radius: 10px;
    /* visibility: hidden; */
}
.otp-main .head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #9CA3AF;
    padding: 0 0 24px;
    margin: 0 0 24px;
}
.otp-main .head h2 {
    color: #000;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    font-family: 'Open Sans', sans-serif;
}
.otp-main .head button {
    background: none;
    border: none;
}
.otp-main  .details {
    width: auto;
}
.otp-main  .details p, .otp-main  .details span {
    color: #4B5563;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Open Sans', sans-serif;
}
.otp-main  .details p a {
    font-weight: bold;
    color: #4B5563;
}

.otp-main  .details .input-field {
    width: auto;
    display: flex;
    margin: 24px 0 20px;
    gap: 10px;
}

.otp-main  .details .input-field input {
    width: 35px;
    height: 35px;
    border-radius: 8px;
    background-color: #F7F7F7;
    border: 1px solid #9CA3AF;
    text-align: center;
    outline: none;
}
.otp-main  .details .input-field input:focus {
    border: 2px solid #1980da;
}
.otp-main  .details span.resend {
    color: #6B7280;
    font-size: 14px;
    line-height: 21px;
    font-family: 'Open Sans', sans-serif;
    display: block;
}
.otp-main  .details span.resend button.resend-btn {
    background-color: transparent;
    border: none;
    font-weight: bold;
    color: #6B7280;
}
.otp-main  .details .verify-btn {
    width: 100%;
    height: 48px;
    border-radius: 12px;
    background-color: #1980DA;
    border: none;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    font-family: 'Open Sans', sans-serif;
    margin: 24px 0 0;
}

@media screen and (max-width:575px) {
    .otp-main {
        width: 300px;
    }
}