/* change password popup */
.change-password-overlay {
    width: 100% !important;
    height: 100% !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    /* Adjust the alpha value for transparency */
    z-index: 99999 !important;
    position: fixed;
    top: 0px;
    bottom: 0px;

}

.change-password-main {
    width: 378px;
    border-radius: 10px;
    background-color: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    opacity: 1;
    /* visibility: hidden; */
    z-index: 1111;
    padding: 29px 30px 19px 30px;
    transform: translate(-50%, -50%);
}

.change-password-main.change-active {
    opacity: 1;
    visibility: visible;
}

.change-password-main.change-active {
    opacity: 1;
    visibility: visible;
}

.change-password-main .change-head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 43px;
}

.change-password-main .change-head h2 {
    font-weight: 600;
    font-size: 20px;
    color: #000;
}

.change-password-main .change-head button {
    background: none;
    border: none;
}

.change-password-main .input-field {
    width: 100%;
    margin: 0 0 30px;
}

.change-password-main .input-field label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #4f4f4f;
    font-family: 'Rubik', sans-serif;
    margin: 0 0 8px;
}

.change-password-main .input-field .password {
    width: 100%;
    position: relative;
}

.change-password-main .input-field .password input {
    width: 100%;
    height: 43px;
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    padding: 0 19px;
}

.change-password-main .input-field .password span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 17px;
    cursor: pointer;
}

.change-password-main .password-btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.change-password-main .password-btn button {
    width: 88px;
    height: 48px;
    border: none;
    background: none;
    border-radius: 8px;
    font-size: 16px;
    line-height: 16px;
    color: #1980DA;
    letter-spacing: 1px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
}

.change-password-main .password-btn button.Save-btn {
    background-color: #1980DA;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 8px;
}

@media screen and (max-width: 575px) {
    .change-password-main {
        padding: 15px;
        width: 320px;
    }
    .change-password-main .input-field .password span img {
        width: 20px;
    }
    .change-password-main .password-btn button {
        height: 40px;
    }
}
