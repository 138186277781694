.No_home {
    width: 100%;
    display: inline-block;
    margin: -20px 0 117px;
}
.blank_home {
    width: 100%;
    text-align: center;
    background-color: #fff;
    padding: 204px 0 164px;
}

.blank_home p {
    font-size: 25px;
    font-weight: 500;
    line-height: 30px;
    margin: 40px 0 0;
}

@media screen and (max-width:450px) {
    .blank_home img {
        width: 90%;
    }
    .No_home {
        margin: 20px 0;
    }
    .blank_home p {
        font-size: 22px;
    }
}