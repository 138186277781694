.banner-text .banner-right .login-main,
.banner-text .banner-right .register-main {
    width: 100%;
}

.banner-text .banner-right .register-main {
    display: block;
}

.banner-text .banner-right .login-main h2,
.banner-text .banner-right .register-main h2 {
    font-weight: bold;
    font-size: 35px;
    margin: 0 0 25px;
}

/* input field placeholder design  */
input::placeholder {
    font-weight: bold;
    opacity: 0.5;
  }
  

.banner-text .banner-right .login-main .input-field,
.banner-text .banner-right .register-main .input-field {
    width: 576px;
    height: 60px;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
    margin: 0 0 18px;
}

.banner-text .banner-right .login-main .input-field .input-left,
.banner-text .banner-right .register-main .input-field .input-left {
    width: 90%;
}
.banner-text .banner-right .login-main .input-field .input-left input.password-place,
.banner-text .banner-right .register-main .input-field .input-left input.password-place{
    padding: 10px 0 0 !important;
}

.banner-text .banner-right .login-main .input-field .input-left label,
.banner-text .banner-right .register-main .input-field .input-left label {
    width: 100%;
    display: block;
    font-weight: 500;
    font-size: 14px;
    margin: 0 0 6px;
    color: rgba(238, 238, 238, 70%);
}

.banner-text .banner-right .login-main .input-field .input-left input,
.banner-text .banner-right .register-main .input-field .input-left input {
    background: transparent;
    border: none;
    width: 100%;
    /* height: 37px; */
    outline: none;
    color: #fff;
    font-size: 18px;
    /* padding: 10px 0 0; */
}

.banner-text .banner-right .login-main .input-field .input-left input::placeholder,
.banner-text .banner-right .register-main .input-field .input-left input::placeholder {
    color: #EEEEEE;
    font-weight: normal;
    font-size: 20px;
}

.banner-text .banner-right .login-main .input-field .input-right,
.banner-text .banner-right .register-main .input-field .input-right {
    width: 10%;
    text-align: right;
}

.banner-text .banner-right .login-main .input-field .input-right span,
.banner-text .banner-right .register-main .input-field .input-right span {
    cursor: pointer;
}
.banner-text .banner-right .login-main .input-field .input-right span img,
.banner-text .banner-right .register-main .input-field .input-right span img {
    width: 30px;
}
.banner-text .banner-right .login-main .input-field .input-right span svg,
.banner-text .banner-right .register-main .input-field .input-right span svg {
    width: 30px;
}

.banner-text .banner-right .login-main .new-member,
.banner-text .banner-right .register-main .already-member {
    width: auto;
    margin: 0 0 38px;
}

.banner-text .banner-right .login-main .new-member p,
.banner-text .banner-right .register-main .already-member p {
    font-weight: 600;
    font-size: 18px;
    display: flex;
    gap: 7px;
    font-family: 'Open Sans', sans-serif;
}

.banner-text .banner-right .login-main .new-member p span,
.banner-text .banner-right .register-main .already-member p span {
    color: #1980DA;
    cursor: pointer;
}

.banner-text .banner-right .login-main .login-btn-main,
.banner-text .banner-right .register-main .login-btn-main {
    width: 100%;
}

.banner-text .banner-right .login-main .login-btn-main button.login-btn,
.banner-text .banner-right .register-main .login-btn-main button.login-btn {
    width: 576px;
    height: 60px;
    background-color: #1980DA;
    border-radius: 12px;
    border: none;
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    
}

.country_code_box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.country_code_box>select {
    background: none;
    color: white;
    border: navajowhite;
    outline: none;
}

.country_code_box>select>option {
    background: rgba(0, 0, 0, 50%);
    color: white;
    border: navajowhite;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
}

.country_code_box>select>option::-webkit-scrollbar {
    display: none;
}

.country_code_box>select::-webkit-scrollbar {
    display: none;
}


@media screen and (max-width:1279px) {
    .banner-text {
        flex-wrap: wrap;
        gap: 45px;
    }

    .banner-text .banner-left,
    .banner-text .banner-right {
        width: 100%;
    }

    .banner-img img {
        height: 1500px;
    }

    .banner-text .banner-right .login-main .login-btn-main button.login-btn,
    .banner-text .banner-right .register-main .login-btn-main button.login-btn {
        width: 350px;
    }

    .banner-text .banner-right .login-main {
        margin: 0;
    }

    .banner-text .banner-right .login-main .login-btn-main button.login-btn,
    .banner-text .banner-right .register-main .login-btn-main button.login-btn {
        width: 576px;
    }

    .banner-text .banner-left {
        margin: 85px 0 0;
    }
}

@media screen and (max-width: 1099px) {
    .banner-text-main {
        transform: none;
    }
}

@media screen and (max-width: 991px) {
    .powered-by p {
        font-size: 22px;
    }

    .banner-text .banner-left h1 {
        font-size: 45px;
    }

    .banner-text .banner-left span {
        font-size: 24px;
    }

    .banner-text .banner-left a {
        width: 202px;
        height: 50px;
    }

    .banner-text .banner-right .login-main .login-btn-main button.login-btn,
    .banner-text .banner-right .register-main .login-btn-main button.login-btn {
        height: 50px;
        width: 240px;
    }

    .banner-text .banner-right .login-main h2,
    .banner-text .banner-right .register-main h2 {
        font-size: 32px !important;
        margin: 0 0 20px !important;
    }

    .banner-text .banner-right .login-main .input-field,
    .banner-text .banner-right .register-main .input-field {
        width: 100% !important;
    }

    .banner-img img {
        height: 1200px;
    }
    .banner-text .banner-left p.light-house {
        margin: 9px 0 30px;
    }
    .banner-text .banner-right .register-main {
        margin: 0 0 0;
    }

}

@media screen and (max-width:767px) {
    .banner-text .banner-left h1 {
        font-size: 40px;
    }

    .banner-text .banner-left p {
        font-size: 20px;
    }

    .banner-text .banner-left a {
        width: 160px;
        font-size: 18px;
    }

    .banner-text .banner-right .login-main .new-member,
    .banner-text .banner-right .register-main .already-member {
        margin: 0 0 25px;
    }

    .banner-text .banner-right .login-main .login-btn-main button.login-btn,
    .banner-text .banner-right .register-main .login-btn-main button.login-btn {
        width: 100%;
        height: 45px;
        font-size: 16px;
    }

    .banner-img img {
        height: 1300px;
    }
}

@media screen and (max-width:639px) {
    /* .banner-img img {
        height: 880px;
    } */
}

@media screen and (max-width: 575px) {
    .banner-text .banner-left h1 {
        font-size: 30px;
        margin: 5px 0 0;
    }

    .banner-text .banner-left span {
        font-size: 18px;
    }

    .banner-text .banner-right .login-main .input-field,
    .banner-text .banner-right .register-main .input-field {
        width: 100% !important;
        padding: 0 10px !important;
        height: 45px !important;
        margin: 0 0 15px !important;
    }

    .banner-text .banner-left p {
        font-size: 13px;
        margin: 18px 0;
    }
    .banner-text .banner-left h4 {
        font-size: 18px;
        line-height: 12px;
    }

    .banner-text .banner-left a {
        height: 40px;
        width: 140px;
        font-size: 15px;
    }

    .banner-text .banner-right .login-main h2,
    .banner-text .banner-right .register-main h2 {
        font-size: 25px !important;
        margin: 0 0 14px !important;
    }

    .banner-text .banner-right .login-main .input-field .input-left input::placeholder,
    .banner-text .banner-right .register-main .input-field .input-left input::placeholder {
        font-size: 16px;
    }

    .banner-text .banner-right .login-main .input-field .input-left input,
    .banner-text .banner-right .register-main .input-field .input-left input {
        font-size: 16px;
        padding: 0;
    }

    .banner-text .banner-right .login-main .input-field .input-right span img,
    .banner-text .banner-right .register-main .input-field .input-right span img {
        width: 20px;
    }
    .banner-text .banner-right .login-main .input-field .input-right span svg,
    .banner-text .banner-right .register-main .input-field .input-right span svg {
        width: 20px;
    }

    .banner-text .banner-right .login-main .new-member,
    .banner-text .banner-right .register-main .already-member {
        margin: 0 0 15px;
    }

    .banner-text .banner-right .login-main .new-member p,
    .banner-text .banner-right .register-main .already-member p {
        font-size: 14px;
        gap: 6px;
    }

    .banner-text-main {
        top: 35px;
    }

    .banner-text .banner-left {
        margin: 20px 0 0;
    }

    .banner-img img {
        height: 1100px;
    }
    
    .powered-by p {
        font-size: 16px;
    }

    .country_code_box>select {
        margin: 0 !important;
        font-size: 16px !important;
    }
}