.filter-home-col {
    width: 33.33%;
    float: left;
    padding: 0 15px;
}

.filter-home-main {
    width: 100%;
    border-radius: 9px;
    background-color: #fff;
    margin: 0 0 40px;
    box-shadow: 0 4px 4px rgba(25,128,218,25%);
    cursor: pointer;
}
.filter-home-main span {
    width: 100%;
}
.filter-home-main span img {
    width: 100%;
    object-fit: cover;
    border-radius: 9px 9px 0 0;
    height: 190px;
}

.filter-home-main .home-details {
    width: 100%;
    padding: 20px;
    height: 300px;
}

.filter-home-main .home-details .home-price {
    width: 100%;
    display: flex;
}
.filter-home-main .home-details .home-price .price {
    width: calc(100% - 57px);
}
.filter-home-main .home-details .home-price .price span {
    font-weight: 600;
    font-size: 27px;
    color: #1980DA;
    font-family: 'Open Sans', sans-serif;
}
.filter-home-main .home-details .home-price .price span span.bed {
    font-weight: normal;
    font-size: 18px;
    color: #808494;
}
.filter-home-main .home-details .home-price .price h3 {
    color: #4C5369;
    font-size: 26px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    margin: 16px 0 0;
    min-height: 60px;
    text-transform: capitalize;
}
.filter-home-main .home-details .home-price button {
    width: 50px;
    height: 50px;
    background-color: #E9F3FC;
    border: 1px solid #C6E4FF;
    border-radius:50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* .filter-home-main .home-details .home-price button svg {
    background-color: #fff;
} */
.filter-home-main .home-details p {
    font-weight: normal;
    font-size: 17px;
    color: #A0A3AF;
    margin: 22px 0;
    min-height: 40px;
}
.filter-home-main .home-details .available-beds {
    width: 100%;
    border-top: 1px solid #D5ECFF;
    display: flex;
    padding: 20px 0 0;
}
.filter-home-main .home-details .available-beds span {
    display: flex;
    gap: 8px;
}
.filter-home-main .home-details .available-beds span img {
    width: 25px;
    height: auto;
}
@media screen and (max-width:1365px) { 
    .filter-home-main .home-details p {
        min-height: 46px;
    }
}

@media screen and (max-width:1099px) {
    .filter-home-main .home-details p {
        min-height: 40px;
    }
    .filter-home-main .home-details .home-price .price h3 {
        font-size: 22px;
    }
}

@media screen and (max-width:991px) {
    .filter-home-main .home-details .home-price .price h3 {
        min-height: 40px;
    } 
}

@media screen and (max-width:770px) {
    .filter-home-main .home-details p {
        min-height: 40px;
    }
    .filter-home-main .home-details .home-price .price h3 {
        min-height: 50px;
        font-size: 22px;
    }
    .filter-home-main .home-details .home-price button {
        width: 40px;
        height: 40px;
    }
}

@media screen and (max-width:575px) {
    .filter-home-main .home-details .home-price button {
        position: absolute;
        top: 20px;
        right: 15px;
    }
    .filter-home-main {
        position: relative;
        box-shadow: none;
        border: 2px solid #1980DA;
        height: max-content;
    }
    .filter-home-main .home-details {
        height: max-content;
    }
    .filter-home-main .home-details .home-price .price {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
    .filter-home-main .home-details .home-price .price span {
        font-size: 18px;
        width: max-content;
        order: 2;
    }
    .filter-home-main .home-details .home-price .price span span.bed {
        font-size: 12px;
    }
    .filter-home-main .home-details .home-price .price h3 {
        min-height: auto;
        font-size: 18px;
        margin: 0;
        order: 1;
    }
    .filter-home-main .home-details .available-beds {
        border: none;
        padding: 0;
    }
    .filter-home-main .home-details .available-beds span {
        color: #1980DA;
    }
    .filter-home-main .home-details .available-beds span img {
        width: 23px;
        height: auto;
    }
    .filter-home-main .home-details p {
        min-height: 40px;
        margin: 12px 0 0;
    }
    .filter-home-main span img {
        height: 150px;
    }
    #search-recovery-main .slick-dots {
        display: none !important;
    }
}