
body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #EDF1F9;
    color: #000;
  }
  
  *{
    padding: 0;
    margin: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
  }
  
  
  
  .privacy-container {
    width: 100%;
    max-width:1170px;
    padding: 0 15px;
    margin: 0 auto;
  }
  
  
  
  /* privacy pages css start */
  #terms-main {
    width: 100%;
    display: inline-block;
    margin: 27px 0 100px;
  }
  
  .terms-col {
    width: 100%;
    float: left;
    padding: 0 15px;
  }
  
  .terms-details {
    width: 100%;
    display: inline-block;
  }
  
  .terms-details p {
    line-height: 24px;
    color: #4F4F4F;
  }
  
  .terms-details .our-term {
    width: 100%;
    margin: 0 0 46px;
  }
  
  .terms-details .our-term h1 {
    font-weight: bold;
    font-size: 34px;
    color: #000;
    margin: 0 0 11px;
  }
  
  .terms-update {
    width: 100%;
    margin: 0 0 35px;
  }
  
  .terms-update h2 {
    font-weight: bold;
    font-size: 22px;
    color: #000;
    margin: 0 0;
  }
  
  .terms-update .first {
    margin: 0 0 40px;
  }
  
  .terms-update .first p {
    margin: 10px 0;
  }
  
  .terms-update .first a {
    color: #1980DA;
  }
  .terms-update .first ul {
    padding: 0 0 0 20px;
  }
  
  .terms-update .first ul li {
    list-style-type: disc;
    margin: 10px 0;
  }
   
  #contact-main {
  width: 100%;
  display: inline-block;
  margin: 50px 0 65px;
}

.back-btn {
  width: 100%;
  display: flex;
  margin: 0 0 66px;
}
.back-btn button {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #1980DA;
  line-height: 34px;
  font-size: 25px;
  gap: 20px;
}

.contact-form {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.contact-form .details {
  width: 60%;
}
.contact-form .details h2 {
  font-weight: bold;
  font-size: 34px;
  line-height: 47px;
  color: #000;
}
.contact-form .details p {
  font-size: 18px;
  line-height: 26px;
  font-weight: normal;
  color: #000;
  margin: 11px 0 0;
  max-width: 580px;
}
.contact-form .details ul li {
  display: flex;
  align-items: center;
  gap: 38px;
  margin: 34px 0;
}
.contact-form .details ul li span {
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
}
.contact-form .details ul li a {
  color: #000;
}

.contact-form form {
  width: 45%;
  background-color: #FBFAF8;
  border-radius: 9px;
  padding: 30px;
}
.contact-form form h3 {
  color: #000;
  font-weight: 600;
  font-size: 24px;
  margin: 0 0 22px;
}

.contact-form form .input-field {
  width: 100%;
  margin: 0 0 20px;
}
.contact-form form .input-field input,
.contact-form form .input-field textarea {
  width: 100%;
  height: 54px;
  border: none;
  background-color: #fff;
  padding: 0 23px;
  border-radius: 9px;
  color: #828282;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
}
.contact-form form .input-field textarea {
  padding: 17px 23px;
  height: 100px;
}
.contact-form form button {
  width: 100%;
  height: 50px;
  background-color: #1980DA;
  border: none;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  border-radius: 9px;
}


@media screen and (max-width:991px) {
  .contact-form {
      flex-wrap: wrap;
  }
  .contact-form .details {
      width: 100%;
  }
  .contact-form form {
      width: 100%;
      margin: 30px 0 0;
      padding: 15px;
  }
  .back-btn {
      margin: 0 0 15px;
  }
  .back-btn button {
      font-size: 20px;
      gap: 10px;
  }
  .contact-form .details ul li {
      gap: 15px;
      margin: 20px 0;
  }
  .contact-form .details p {
      font-size: 18px;
  }
  .contact-form .details ul li span {
      font-size: 18px;
      line-height: 25px;
  }
  .contact-form .details ul li span  img{
      width: 30px;
  }
}

@media screen and (max-width:575px) {
  #contact-main {
      margin: 45px 0;
  }
  .back-btn {
      margin: 0 0 10px;
  }
  .back-btn button {
      font-size: 18px;
      gap: 8px;
  }
  .back-btn button img {
      width: 8px;
  }
  .contact-form .details h2 {
      font-size: 24px;
      line-height: 24px;
  }
  .contact-form .details p {
      font-size: 16px;
      line-height: 22px;
  }
  .contact-form .details ul li span {
      font-size: 15px;
      line-height: 20px;
  }
  .contact-form .details ul li {
      gap: 10px;
      margin: 16px 0;
  }
  .contact-form form h3 {
      margin: 0 0 15px;
      font-size: 18px;
  }
  .contact-form form button {
      font-size: 16px;
      font-weight: 500;
      height: 45px;
  }
}