/* search recovery home */
#search-recovery-main {
    width: 100%;
    display: inline-block;
    margin: 72px 0 15px;
}
.container {
    width: 100%;
    max-width:1230px;
    padding: 0 15px;
    margin: 0 auto;
}




.recoveryhead-col {
    width: 100%;
    float: left;
    padding: 0 15px;
}
.recoveryhead {
    width: 100%;
    text-align: center;
    margin: 0 0 50px;
}

.recoveryhead h2 {
    font-weight: bold;
    font-size: 40px;
}

.filter-bar-col {
    width: 100%;
    /* float: left; */
    padding: 0 15px;
}

.filter-bar-main {
    width: 100%;
    margin: 0 0 66px;
    position: relative;
}
.filter-bar-main .filter-bar {
    width: 100%;
    display: inline-block;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 0 4px #1980DA;
    display: flex;
    padding: 29px 36px;
    gap: 40px;
}
.filter-bar-main .filter-bar .filter {
    width: 20%;
    border-right: 1px solid #000;
    padding: 0 20px 0 0;
}

.filter-bar-main .filter-bar .filter button.search-btn {
    width: 100%;
    height: 50px;
    background-color: #1980DA;
    border-radius: 12px;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
    border: none;
    width: 120px;
}
.filter-bar-main .filter-bar .filter.btn {
    border: none;
    padding: 0 0 0 30px;
    width: 15%;
    display: flex;
    align-items: center;
}
.filter-bar-main .filter-bar .filter h3 {
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
}
.filter-bar-main .filter-bar .filter textarea {
    color: #1980DA;
    font-weight: 600;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    margin: 6px 0 0;
    display: block;
    border: none;
    outline: none;
    cursor: pointer;
}

.dropdownAddressList {
    border: 1px solid gray;
    position: absolute;
    background-color: white;
    width: 209px;
    padding: 12px;
    max-height: 168px;
    overflow-y: scroll;

}
.dropdownAddressList ul li {
   padding: 3px;

}
.filter-bar-main .filter-bar .filter span , .filter-bar-main .filter-bar .filter button.filter-dropdown {
    color: #1980DA;
    font-weight: 600;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    margin: 6px 0 0;
    display: block;
}
.filter-bar-main .filter-bar .filter button.filter-dropdown {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    font-size: 17px;
}
.filter-bar-main .filter-bar .filter button.filter-dropdown .filter-dropdown-main {
    width: 100%;
    background-color: #fff;
    border-radius: 0 0 10px 10px;
    position: absolute;
    top: 35px;
    left: 0;
    box-shadow: 0 4px 18px rgba(0,0,0,9%);
}
.filter-bar-main .filter-bar .filter button.filter-dropdown.active .filter-dropdown-main {
    opacity: 1;
    visibility: visible;
} 
.filter-bar-main .filter-bar .filter button.filter-dropdown .filter-dropdown-main ul {
    text-align: left;
}
.filter-bar-main .filter-bar .filter button.filter-dropdown .filter-dropdown-main ul li {
    font-size: 14px;
    color: #000;
    padding: 10px 0 10px 21px;
    border-bottom: 1px solid #EFEFEF;
}
.filter-bar-main .filter-bar .filter button.filter-dropdown span {
    width: 24px;
    height: 24px;
    background-color: #EAF4FD;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.filter-bar-main button.add-filter {
    background: transparent;
    border: none;
    color: #1980DA;
    font-weight: 600;
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
    margin: 10px 0 0;
}

.filter-home-col {
    width: 33.33%;
    float: left;
    padding: 0 15px;
}

.filter-home-main {
    width: 100%;
    border-radius: 9px;
    background-color: #fff;
    margin: 0 0 40px;
    box-shadow: 0 4px 4px rgba(25,128,218,25%);
}
.filter-home-main span {
    width: 100%;
}
.filter-home-main span img {
    width: 100%;
}

.filter-home-main .home-details {
    width: 100%;
    padding: 39px 29px;
}

.filter-home-main .home-details .home-price {
    width: 100%;
    display: flex;
}
.filter-home-main .home-details .home-price .price {
    width: calc(100% - 57px);
}
.filter-home-main .home-details .home-price .price span {
    font-weight: 600;
    font-size: 32px;
    color: #1980DA;
    font-family: 'Open Sans', sans-serif;
}
.filter-home-main .home-details .home-price .price span span.bed {
    font-weight: normal;
    font-size: 18px;
    color: #808494;
}
.filter-home-main .home-details .home-price .price h4 {
    color: #4C5369;
    font-size: 30px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    margin: 16px 0 0;
}
.filter-home-main .home-details .home-price button {
    width: 57px;
    height: 57px;
    background-color: #E9F3FC;
    border: 1px solid #C6E4FF;
    border-radius:50%;
}
.filter-home-main .home-details p {
    font-weight: normal;
    font-size: 20px;
    color: #A0A3AF;
    margin: 22px 0;
}
.filter-home-main .home-details .available-beds {
    width: 100%;
    border-top: 1px solid #D5ECFF;
    display: flex;
    padding: 20px 0 0;
}
.filter-home-main .home-details .available-beds span {
    display: flex;
    gap: 8px;
}
.filter-home-main .home-details .available-beds span img {
    width: auto;
}


.next-previous-col {
    width: 100%;
    float: left;
    padding: 0 15px;
}
.next-previous-main {
    width: 100%;
    text-align: center;
}
.next-previous-main ul {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
}
.next-previous-main ul li  a.previous-btn {
    margin: 0 30px 0 0;
    display: inline-block;
}
.next-previous-main ul li  a.next-btn {
    display: inline-block;
    margin: 0 0 0 30px;
}

.next-previous-main ul li  span.dots {
    background-color: transparent;
}

.next-previous-main ul li  span {
    display: flex;
    border-radius: 50%;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: 49px;
    height: 49px;
    font-size: 25px;
    font-weight: 600;
    background-color: #fff;
    color: #000;
    font-family: 'Open Sans', sans-serif;
}

.next-previous-main ul li  span.active {
    background-color: #1980DA;
    color: #fff;
}
.back_btn_mobile button {
    width: 60px;
    height: 60px;
    background-color: #1980DA;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 30px #6fb3ee;
    border: 1px solid #fff;
    position: fixed;
    right: 15px;
    top: 80%;
}
.back_btn_mobile button img {
    width: 30px;
}

.dropdownAddressList {
    padding: 0;
    z-index: 1;
}
.dropdownAddressList ul li {
    padding: 7px 4px;
    font-size: 13px;
    /* margin: 0 0 10px; */
    border-bottom: 1px solid #ded9d9;
    cursor: pointer;
}
@media screen and (max-width:991px) {
    .recoveryhead h2 {
        font-size: 32px;
    }
}

@media screen and (max-width:767px) {
    .filter-bar-main .filter-bar .filter h3 {
        font-size: 18px;
    }
    .filter-bar-main .filter-bar .filter span, .filter-bar-main .filter-bar .filter button.filter-dropdown {
        font-size: 20px;
    }
    .filter-bar-col {
        padding: 0;
    }
}